
import {
    apiSettingsSystemRateLists,
    apiSettingsSystemRateDel,
    apiSettingsSystemRateAdd,
    apiSettingsSystemRateEdit
} from "@/api/setting";
import { Component, Prop, Vue } from "vue-property-decorator";
import LsPagination from "@/components/ls-pagination.vue";
import { RequestPaging } from "@/utils/util";
import LsDialog from "@/components/ls-dialog.vue";
@Component({
    components: {
        LsDialog,
        LsPagination,
    },
})
export default class Task extends Vue {
    // 分页
    pager: RequestPaging = new RequestPaging();
    apiSettingsSystemRateLists = apiSettingsSystemRateLists
    showDialog = false
    form:any = {
        name: '',
        currency: '',
        symbol: '',
        rate: ''
    }
    rules: object = {
        name: [{ required: true, message: '请输入货币名称', trigger: 'blur' }],
        currency: [{ required: true, message: '请输入货币代码', trigger: 'blur' }],
        symbol: [{ required: true, message: '请输入货币符号', trigger: 'blur' }],
        rate: [{ required: true, message: '请输入货币汇率', trigger: 'blur' }],
    }
    formId: null | string = null

    dialogClose(){
        this.formId = null;
        Object.keys(this.form).forEach((v:any)=>{
            this.form[v] = ''
        })
    }

    doHandle(formName:string) {
        const refs = this.$refs[formName] as HTMLFormElement
        refs.validate((valid: boolean): any => {
            if (!valid) return
            let api: any = '';
            if(this.formId === null) {
                api = apiSettingsSystemRateAdd
            } else {
                api = apiSettingsSystemRateEdit
            }
            api({
                ...this.form,
                id: this.formId === null ? '' : this.formId
            }).
            then((res:any)=>{
                this.getList();
                this.showDialog = false;
            })
        })
    }

    addCurrency() {
        this.showDialog = true;
    }

    edit(item: any) {
        this.formId = item.id;
        this.form.name = item.name;
        this.form.currency = item.currency;
        this.form.symbol = item.symbol;
        this.form.rate = item.rate;
        this.showDialog = true;
    }

    refresh(){
        this.getList();
    }

    // 获取列表
    getList() {
        this.pager.request({
            callback: apiSettingsSystemRateLists,
            params: {}
        });
    }

    // 删除这个定时任务
    async onDel(id: any): Promise<void> {
        await apiSettingsSystemRateDel({ id: id });
        this.getList();
    }

    created() {
        this.getList();
    }
}
